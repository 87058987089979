import api from './api'

export default {
    resetpwd(data){
    return api.execute(`post`,`/resetpwd`, data)
  },
  apkget(data){
    return api.execute(`post`,`/setting/apkget`, data)
  },
  apk(data){
    return api.execute(`post`,`/setting/apk`, data)
  },
  getdevapi(data){
    return api.execute(`get`,`/setting/apikeys`, data)
  },    
  generatemasterkey(data){
    return api.execute(`post`,`/setting/masterkey`, data)
  },
  updateapikeyholder(data){
    return api.execute(`put`,`/setting/apikeys`, data)
  }, 
  addapikeyholder(data){
    return api.execute(`post`,`/setting/apikeys`, data)
  },
  deleteapikey(data){
    return api.execute(`delete`,`/setting/apikeys`, data)
  },
  domainget(data){
    return api.execute(`post`,`/setting/domainget`, data)
  },
  domain(data){
    return api.execute(`post`,`/setting/domain`, data)
  },
  ipget(data){
    return api.execute(`post`,`/setting/ipget`, data)
  },
  ip(data){
    return api.execute(`post`,`/setting/ip`, data)
  },
  jsget(data){
    return api.execute(`post`,`/setting/jsget`, data)
  },
  js(data){
    return api.execute(`post`,`/setting/js`, data)
  },
  postipget(data){
    return api.execute(`post`,`/setting/postipget`, data)
  },
  postip(data){
    return api.execute(`post`,`/setting/postip`, data)
  },
  changesetting(data){
    return api.execute(`post`,`/mastersetting/changesetting`, data)
  },
  getsetting(data){
    return api.execute(`post`,`/mastersetting/getsetting`, data)
  },
  getpblogs(data){
    return api.execute(`post`,`/setting/pblogs`,data)
  },
  retryPostback(id){
    return api.execute(`get`,`/pblog/retrypb?pbid=`+id)
  },
  addCustomDomain(data){
    return api.execute(`post`,`/setting/customdomain`,data)
  },
  getCustomDomain(){
    return api.execute(`get`,`/setting/customdomain`)
  },
  getActivitylogs(data){
    return api.execute(`post`,`/setting/get/requestlog`,data)
  },
  setLogoutTimeout(data){
    return api.execute(`put`,`/change/lgtimeout`,data)
  },
  getSubValue(){
    return api.execute(`get`,`/report/options`)
  },
  saveSubValue(payload){
    return api.execute(`post`,`/setting/submapping`,payload)
  },
  updateTimeZone(payload){
    return api.execute(`put`,`/setting/timezone`,payload)
  },
  updateRecipients(payload){
    return api.execute(`put`,`/setting/rptrecipients`,payload)
  }
}
